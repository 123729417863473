.success-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.success-page__title {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.success-page__message {
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
}

/* Add hover effect for a subtle interaction */
.success-page:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
}

/* Responsive styles for smaller screens */
@media only screen and (max-width: 600px) {
  .success-page {
    padding: 20px;
  }

  .success-page__title {
    font-size: 28px;
  }

  .success-page__message {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
